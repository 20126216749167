import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import ROUTES from "@config/routes";
import { logger } from "@helpers/logger";
import { getStorageLogin, resetAuth } from "@helpers/storage";
import { SERVICE_STATUS } from "@libraries/api/services-status/services-status.repos";
import { resetList } from "@redux/actions";
import { useAppDispatch } from "@redux/store";
import { ILoginData } from "./storage-interface";
import { clearCookies } from "./cookie";

export const useAuth = () => {
  const [isAuth, setIsAuth] = useState(false);
  const [loginData, setLoginData] = useState<Partial<ILoginData | undefined>>({});
  const dispatch = useAppDispatch();
  const router = useRouter();

  const logout = () => {
    dispatch(resetList());
    resetAuth();
    setLoginData(undefined);
    setIsAuth(false);
    clearCookies();

    router.push(ROUTES.HOME());
  };

  const checkingSubscribe = async () => {
    try {
      const { data } = await SERVICE_STATUS.GET_SERVICE_STATUS();
      if (data.isAvailable) {
        router.push(ROUTES.DASHBOARD());
      } else {
        router.push(ROUTES.HOME());
      }
    } catch (err) {
      return logger.error(err);
    }
  };

  useEffect(() => {
    setLoginData(getStorageLogin());
  }, []);

  useEffect(() => {
    setIsAuth(!!loginData?.fullName);
  }, [loginData]);

  return {
    isAuth,
    loginData,
    logout,
    checkingSubscribe,
  };
};
